<script setup>
import { onMounted, ref } from "vue";

defineProps({
  modelValue: String,
  type: {
    type: String,
    default: "text",
  },
  class: String,
  disabled: Boolean,
  style: String,
});

defineEmits(["update:modelValue", "focusin", "focusout", "keypress"]);

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute("autofocus")) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <input
    ref="input"
    :type="type"
    :class="class"
    :disabled="disabled"
    :style="style"
    :value="modelValue"
    @keydown="(e) => $emit('keypress', e)"
    @focusin="$emit('focusin')"
    @focusout="$emit('focusout')"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
